import React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import { Link } from "react-scroll";

import { Title, Section, Box, Text, Button } from "../../components/Core";
import IconCircle from "../../components/IconCircle";

import bgHero from "../../assets/image/png/subscribe-pattern.png";
import dioscuros from "../../assets/image/jpg/dioscuros.jpg";
import university from "../../assets/image/png/university.png";
import profile from "../../assets/image/png/profile.png";
import carrer_development from "../../assets/image/png/carrer_development.png";
import challenge from "../../assets/image/png/challenge.png";
import how_we_do_it from "../../assets/image/png/how_we_do_it.png";

const SectionStyled = styled(Section)`
  &::before {
    opacity: 0.085;
    position: absolute;
    top: 0;
    content: "";
    width: 100%;
    height: 100%;
    background: url(${bgHero}) top center no-repeat;
    background-size: cover;
    z-index: -1;
  }
`;

const Proposal = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <SectionStyled
        hero
        className="position-relative"
        pt={["50px", null, "75px", "100px"]}
        pb={["100px", null, "150px", "200px"]}
      >
        <Container>
          <Box className="d-flex flex-column align-items-center text-center">
            <IconCircle mb="2.5rem">
              <i className="icon icon-code-2"></i>
            </IconCircle>

            <Title variant="hero" mb="1.5rem">
            Why Dioscuro.?
            </Title>
            <Text
              color="text"
              css={`
                line-height: 1.5;
              `}
              mb="2.5rem"
            >
              The <strong>Dioscuri or Dioskouroi</strong>, are both mortal, sometimes both divine. <br/> As a reference in the Greek Mythology we are a symbol, of the connection between the Earth and The Sky.

              Therefore, our company wants to enable, through technology, the connection between your company and the cloud.

              <img src={dioscuros} alt="dioscuros" className="img-fluid rounded float-end"/>

              <br /><br />
              We <strong>develop experience in new devs</strong> to help you complete your teams in record time.

              <br />
              Not a coding school, not a software factory. A customized solution for large companies

              <img src={how_we_do_it} alt="how_we_do_it" className="img-fluid rounded float-end"/>

            </Text>
            <Link
              to="works"
              spy={true}
              smooth={true}
              offset={-50}
              duration={1000}
            >
              <Button>Demo</Button>
            </Link>
          </Box>
          <Box className="d-flex flex-column align-items-center text-center">
            <Title variant="hero" mb="1.5rem">
            Carrer Development System
            </Title>
            <Text
              color="text"
              css={`
                line-height: 1.5;
              `}
              mb="2.5rem"
            >
              A well-rounded application of career development tools establishes a robust framework that facilitates a growth mindset, encourages wholesome employee development, and drives organizational success.
              <br /><br />

              <img src={carrer_development} alt="carrer_development" className="img-fluid rounded float-end"/>
            </Text>
          </Box>
          <Box className="d-flex flex-column align-items-center text-center">
            <Title variant="hero" mb="1.5rem">
            Profile Tool
            </Title>
            <Text
              color="text"
              css={`
                line-height: 1.5;
              `}
              mb="2.5rem"
            >
              The Profile Tool is a web-based application that allows users to create, edit, and delete profiles. The application is built using React, Redux, and Material-UI.

              <br /><br />

              <img src={profile} alt="profile" className="img-fluid rounded float-end"/>
            </Text>
          </Box>
          <Box className="d-flex flex-column align-items-center text-center">
            <Title variant="hero" mb="1.5rem">
            Code Challenge Tool
            </Title>
            <Text
              color="text"
              css={`
                line-height: 1.5;
              `}
              mb="2.5rem"
            >
              Helping you become a better software engineer through coding challenges that build real applications.
              <br /><br />

              <img src={challenge} alt="challenge" className="img-fluid rounded float-end"/>
            </Text>
          </Box>
          <Box className="d-flex flex-column align-items-center text-center">
            <Title variant="hero" mb="1.5rem">
            Dioscuro University
            </Title>
            <Text
              color="text"
              css={`
                line-height: 1.5;
              `}
              mb="2.5rem"
            >
              Step-by-Step Courses and Tutorials for Mastering Cloud Native Technologies.
              <br /><br />
              <img src={university} alt="university" className="img-fluid rounded float-end"/>
            </Text>
          </Box>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Proposal;
